<template>
  <td v-if="!dataItem['inEdit']">
    <v-btn
      elevation="0"
      height="32"
      class="font-weight-bold text-xs btn-default bg-gradient-primary"
      color="#5e72e4"
      id="inverter_edit"
      @click="editHandler"
    >
      Edit
    </v-btn>
    <v-btn
      elevation="0"
      height="32"
      class="
        font-weight-bold
        text-xs
        btn-default btn-outline-default
        shadow-none
      "
      color="transparent"
      id="inverter_remove"
      @click="removeHandler"
    >
      Remove
    </v-btn>
  </td>
  <td v-else>
    <v-btn
      elevation="0"
      height="32"
      class="font-weight-bold text-xs btn-default bg-gradient-primary"
      color="#5e72e4"
      id="inverter_add"
      @click="addUpdateHandler"
    >
      {{ dataItem.ProductID ? "Update" : "Add" }}
    </v-btn>
    <v-btn
      elevation="0"
      height="32"
      class="
        font-weight-bold
        text-xs
        btn-default btn-outline-default
        shadow-none
      "
      color="transparent"
      id="inverter_discard"
      @click="cancelDiscardHandler"
    >
      {{ dataItem.ProductID ? "Cancel" : "Discard" }}
    </v-btn>
  </td>
</template>
<script>
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  methods: {
    editHandler: function () {
      this.$emit("edit", { dataItem: this.dataItem });
    },
    removeHandler: function () {
      this.$emit("remove", { dataItem: this.dataItem });
    },
    addUpdateHandler: function () {
      this.$emit("save", { dataItem: this.dataItem });
    },
    cancelDiscardHandler: function () {
      this.$emit("cancel", { dataItem: this.dataItem });
    },
  },
};
</script>
<style scoped>
#inverter_edit {
  min-width: 0;
  width: 28px;
  float: left;
}
#inverter_remove {
  min-width: 0;
  width: 28px;
  margin-left: 5%;
}
#inverter_add {
  min-width: 0;
  width: 28px;
  float: left;
}
#inverter_discard {
  min-width: 0;
  width: 28px;
  margin-left: 5%;
}
</style>