var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.dataItem["inEdit"]
    ? _c(
        "td",
        [
          _c(
            "v-btn",
            {
              staticClass:
                "font-weight-bold text-xs btn-default bg-gradient-primary",
              attrs: {
                elevation: "0",
                height: "32",
                color: "#5e72e4",
                id: "inverter_edit",
              },
              on: { click: _vm.editHandler },
            },
            [_vm._v(" Edit ")]
          ),
          _c(
            "v-btn",
            {
              staticClass:
                "\n      font-weight-bold\n      text-xs\n      btn-default btn-outline-default\n      shadow-none\n    ",
              attrs: {
                elevation: "0",
                height: "32",
                color: "transparent",
                id: "inverter_remove",
              },
              on: { click: _vm.removeHandler },
            },
            [_vm._v(" Remove ")]
          ),
        ],
        1
      )
    : _c(
        "td",
        [
          _c(
            "v-btn",
            {
              staticClass:
                "font-weight-bold text-xs btn-default bg-gradient-primary",
              attrs: {
                elevation: "0",
                height: "32",
                color: "#5e72e4",
                id: "inverter_add",
              },
              on: { click: _vm.addUpdateHandler },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.dataItem.ProductID ? "Update" : "Add") + " "
              ),
            ]
          ),
          _c(
            "v-btn",
            {
              staticClass:
                "\n      font-weight-bold\n      text-xs\n      btn-default btn-outline-default\n      shadow-none\n    ",
              attrs: {
                elevation: "0",
                height: "32",
                color: "transparent",
                id: "inverter_discard",
              },
              on: { click: _vm.cancelDiscardHandler },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.dataItem.ProductID ? "Cancel" : "Discard") +
                  " "
              ),
            ]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }